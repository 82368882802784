<template>
  <div class="my-account">
    <div class="container">
      <h2 class="title-profile container">{{ $route.name }}</h2>
      <div class="row">
        <div class="col-lg-4">
          <div class="info">
            <div class="head">
              <input
                accept=".png, .jpeg, .jpg"
                type="file"
                id="Avatart"
                hidden
                @change="SetNewAvatart"
              />
              <label for="Avatart" class="avatart-edit">
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.87534 10.8113L1.0309 14.4557C1.00177 14.589 1.00277 14.727 1.03382 14.8598C1.06488 14.9926 1.1252 15.1168 1.21039 15.2232C1.29558 15.3297 1.40349 15.4158 1.52622 15.4753C1.64895 15.5347 1.78342 15.566 1.91979 15.5668C1.98333 15.5733 2.04736 15.5733 2.1109 15.5668L5.77756 14.7224L12.8176 7.70906L8.88867 3.78906L1.87534 10.8113Z"
                    fill="#0F70A6"
                  />
                  <path
                    d="M15.0301 4.19766L12.4079 1.57543C12.2355 1.40391 12.0022 1.30762 11.759 1.30762C11.5158 1.30762 11.2825 1.40391 11.1101 1.57543L9.65234 3.03321L13.5768 6.95766L15.0346 5.49988C15.1199 5.41413 15.1874 5.31241 15.2334 5.20053C15.2794 5.08864 15.3028 4.9688 15.3024 4.84785C15.302 4.72689 15.2777 4.60721 15.231 4.49564C15.1843 4.38407 15.116 4.28281 15.0301 4.19766Z"
                    fill="#0F70A6"
                  />
                </svg>
              </label>
              <img id="target-avatar" :src="image" alt="" />
              <h1 class="name">{{ name }}</h1>
              <div class="email-profile">{{ email }}</div>
            </div>
            <div class="body-data">
              <div class="item">
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.12131 3.10066C6.97338 2.69792 6.67695 2.36706 6.29283 2.17593C5.90872 1.9848 5.46602 1.94789 5.05556 2.07278C3.1098 2.67271 1.74996 4.34251 2.04193 6.27428C2.36845 8.4183 3.09497 10.4819 4.18367 12.3576C5.26071 14.2289 6.67269 15.8863 8.34916 17.247C9.85698 18.4668 11.9727 18.1309 13.4645 16.731C13.7783 16.4365 13.9687 16.0339 13.9973 15.6045C14.0259 15.1751 13.8905 14.7508 13.6185 14.4173L12.5506 13.1115C12.3368 12.8489 12.0489 12.6566 11.7244 12.5596C11.3999 12.4626 11.0538 12.4654 10.7309 12.5675L8.26717 13.3474L7.83722 12.9035C7.2955 12.3416 6.82164 11.7181 6.42539 11.0457C6.04081 10.3657 5.74126 9.64104 5.5335 8.88797L5.36752 8.29804L7.26729 6.54825C7.51797 6.31548 7.69383 6.01346 7.77252 5.68054C7.85121 5.34763 7.82918 4.99884 7.70924 4.67847L7.12131 3.10066ZM4.46763 0.161008C5.36583 -0.113805 6.33512 -0.0347107 7.17688 0.382082C8.01864 0.798874 8.66914 1.52181 8.99508 2.40274L9.58101 3.97855C9.83948 4.67086 9.88623 5.4244 9.71531 6.14334C9.54438 6.86229 9.16351 7.51416 8.62113 8.01607L7.64125 8.91796C7.76723 9.26592 7.93721 9.66387 8.15718 10.0498C8.37716 10.4298 8.63312 10.7757 8.8671 11.0577L10.1269 10.6578C10.8313 10.4354 11.5862 10.4295 12.2939 10.6409C13.0016 10.8524 13.6295 11.2713 14.0965 11.8436L15.1643 13.1495C15.7578 13.8752 16.0536 14.7992 15.992 15.7346C15.9304 16.6701 15.5159 17.5473 14.8324 18.1889C12.8446 20.0546 9.59901 20.8285 7.09331 18.8008C5.22622 17.2861 3.65359 15.441 2.45388 13.3574C1.23932 11.2654 0.42869 8.9637 0.0641685 6.57224C-0.415773 3.38662 1.86995 0.960912 4.46763 0.163007"
                    fill="#0349A8"
                  />
                </svg>
                <span
                  style="
                    direction: ltr;
                    display: inline-block;
                    margin-inline-end: 0;
                    margin-right: 12px;
                  "
                  >{{ phone }}</span
                >
              </div>
              <div class="item" v-if="cityObj">
                <svg
                  width="18"
                  height="20"
                  viewBox="0 0 18 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 0C11.3869 0 13.6761 0.94039 15.364 2.6143C17.0518 4.2882 18 6.55851 18 8.92577C18 11.9744 16.324 14.4697 14.558 16.2598C13.6755 17.1443 12.7128 17.9464 11.682 18.6558L11.256 18.9435L11.056 19.0754L10.679 19.3134L10.343 19.5167L9.927 19.7567C9.64451 19.9161 9.32505 20 9 20C8.67495 20 8.35549 19.9161 8.073 19.7567L7.657 19.5167L7.137 19.1993L6.945 19.0754L6.535 18.8046C5.42298 18.0581 4.38707 17.2058 3.442 16.2598C1.676 14.4687 0 11.9744 0 8.92577C0 6.55851 0.948211 4.2882 2.63604 2.6143C4.32387 0.94039 6.61305 0 9 0ZM9 1.9835C7.14348 1.9835 5.36301 2.71492 4.05025 4.01685C2.7375 5.31877 2 7.08456 2 8.92577C2 11.2286 3.272 13.2498 4.871 14.8723C5.55862 15.5625 6.30174 16.196 7.093 16.7666L7.551 17.0899C7.699 17.192 7.841 17.2872 7.978 17.3755L8.368 17.6234L8.711 17.8307L9 17.9983L9.455 17.7315L9.822 17.5034C10.017 17.3805 10.227 17.2426 10.449 17.0899L10.907 16.7666C11.6983 16.196 12.4414 15.5625 13.129 14.8723C14.728 13.2508 16 11.2286 16 8.92577C16 7.08456 15.2625 5.31877 13.9497 4.01685C12.637 2.71492 10.8565 1.9835 9 1.9835ZM9 4.95876C10.0609 4.95876 11.0783 5.37671 11.8284 6.12067C12.5786 6.86463 13 7.87365 13 8.92577C13 9.97788 12.5786 10.9869 11.8284 11.7309C11.0783 12.4748 10.0609 12.8928 9 12.8928C7.93913 12.8928 6.92172 12.4748 6.17157 11.7309C5.42143 10.9869 5 9.97788 5 8.92577C5 7.87365 5.42143 6.86463 6.17157 6.12067C6.92172 5.37671 7.93913 4.95876 9 4.95876ZM9 6.94226C8.46957 6.94226 7.96086 7.15124 7.58579 7.52322C7.21071 7.8952 7 8.39971 7 8.92577C7 9.45182 7.21071 9.95634 7.58579 10.3283C7.96086 10.7003 8.46957 10.9093 9 10.9093C9.53043 10.9093 10.0391 10.7003 10.4142 10.3283C10.7893 9.95634 11 9.45182 11 8.92577C11 8.39971 10.7893 7.8952 10.4142 7.52322C10.0391 7.15124 9.53043 6.94226 9 6.94226Z"
                    fill="#0349A8"
                  />
                </svg>
                <span>{{  cityName  }}</span>
                <!-- <span>{{ currentLocale === 'ar' && cityObj.name.ar ? cityObj.name.ar : cityObj.name.en }}</span> -->
              </div>
              <div v-if="CompanyName" class="item">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.0261 0.577567C11.2411 0.505287 11.4694 0.483126 11.6941 0.512748C11.9187 0.54237 12.1338 0.623005 12.3232 0.748603C12.5126 0.8742 12.6714 1.0415 12.7876 1.23794C12.9038 1.43438 12.9745 1.65486 12.9943 1.88284L13.0003 2.0162V7.55856L16.7948 8.32637C17.111 8.39002 17.3985 8.5549 17.6146 8.79656C17.8308 9.03822 17.9641 9.3438 17.9949 9.66801L18.0009 9.81147V17.4795H19.001C19.2559 17.4797 19.5011 17.5783 19.6864 17.7551C19.8718 17.9319 19.9833 18.1735 19.9983 18.4305C20.0132 18.6876 19.9304 18.9407 19.7668 19.1382C19.6032 19.3356 19.3711 19.4625 19.118 19.4929L19.001 19.5H0.999003C0.744095 19.4997 0.498915 19.4011 0.313559 19.2243C0.128202 19.0476 0.016659 18.806 0.00172023 18.5489C-0.0132185 18.2919 0.0695745 18.0388 0.233183 17.8413C0.396791 17.6438 0.628866 17.5169 0.88199 17.4865L0.999003 17.4795H1.99911V4.70959C1.99911 4.10342 2.35515 3.56192 2.89721 3.32147L3.02523 3.27197L11.0261 0.577567ZM11.0001 2.71733L3.99934 5.0743V17.4795H11.0001V2.71733ZM13.0003 9.62053V17.4795H16.0007V10.2257L13.0003 9.62053Z"
                    fill="#0349A8"
                  />
                </svg>
                <span>{{ CompanyName }}</span>
              </div>
              <div class="item" v-if="commercial_register">
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.5 2C0.5 1.46957 0.697544 0.960859 1.04917 0.585786C1.40081 0.210714 1.87772 0 2.375 0H13.625C14.1223 0 14.5992 0.210714 14.9508 0.585786C15.3025 0.960859 15.5 1.46957 15.5 2V18C15.5 18.5304 15.3025 19.0391 14.9508 19.4142C14.5992 19.7893 14.1223 20 13.625 20H2.375C1.87772 20 1.40081 19.7893 1.04917 19.4142C0.697544 19.0391 0.5 18.5304 0.5 18V2ZM13.625 2H2.375V18H13.625V2ZM4.25 7C4.25 6.73478 4.34877 6.48043 4.52459 6.29289C4.7004 6.10536 4.93886 6 5.1875 6H10.8125C11.0611 6 11.2996 6.10536 11.4754 6.29289C11.6512 6.48043 11.75 6.73478 11.75 7C11.75 7.26522 11.6512 7.51957 11.4754 7.70711C11.2996 7.89464 11.0611 8 10.8125 8H5.1875C4.93886 8 4.7004 7.89464 4.52459 7.70711C4.34877 7.51957 4.25 7.26522 4.25 7ZM5.1875 11C4.93886 11 4.7004 11.1054 4.52459 11.2929C4.34877 11.4804 4.25 11.7348 4.25 12C4.25 12.2652 4.34877 12.5196 4.52459 12.7071C4.7004 12.8946 4.93886 13 5.1875 13H8C8.24864 13 8.4871 12.8946 8.66291 12.7071C8.83873 12.5196 8.9375 12.2652 8.9375 12C8.9375 11.7348 8.83873 11.4804 8.66291 11.2929C8.4871 11.1054 8.24864 11 8 11H5.1875Z"
                    fill="#0349A8"
                  />
                </svg>
                <span>{{ commercial_register }}</span>
              </div>
            </div>
            <!--            <button class="btn-seconde" @click="logOut">-->
            <!--              <svg-->
            <!--                width="20"-->
            <!--                height="20"-->
            <!--                viewBox="0 0 20 20"-->
            <!--                fill="none"-->
            <!--                xmlns="http://www.w3.org/2000/svg"-->
            <!--              >-->
            <!--                <path-->
            <!--                  d="M8.79936 18.8C8.79936 19.1183 8.67295 19.4235 8.44792 19.6485C8.22289 19.8736 7.91769 20 7.59945 20H1.99986C1.46946 20 0.96079 19.7893 0.585744 19.4142C0.210698 19.0391 0 18.5304 0 18V2C0 1.46957 0.210698 0.960859 0.585744 0.585786C0.96079 0.210714 1.46946 0 1.99986 0H7.59945C7.91769 0 8.22289 0.126428 8.44792 0.351472C8.67295 0.576516 8.79936 0.88174 8.79936 1.2C8.79936 1.51826 8.67295 1.82348 8.44792 2.04853C8.22289 2.27357 7.91769 2.4 7.59945 2.4H2.39983V17.6H7.59945C7.91769 17.6 8.22289 17.7264 8.44792 17.9515C8.67295 18.1765 8.79936 18.4817 8.79936 18.8ZM19.6476 9.151L15.6479 5.151C15.4225 4.92557 15.1167 4.79892 14.7979 4.79892C14.4791 4.79892 14.1734 4.92557 13.948 5.151C13.7226 5.37643 13.5959 5.68219 13.5959 6.001C13.5959 6.31981 13.7226 6.62557 13.948 6.851L15.8988 8.8H7.59945C7.28121 8.8 6.97601 8.92643 6.75098 9.15147C6.52596 9.37652 6.39954 9.68174 6.39954 10C6.39954 10.3183 6.52596 10.6235 6.75098 10.8485C6.97601 11.0736 7.28121 11.2 7.59945 11.2H15.8988L13.947 13.151C13.7216 13.3764 13.5949 13.6822 13.5949 14.001C13.5949 14.3198 13.7216 14.6256 13.947 14.851C14.1724 15.0764 14.4781 15.2031 14.7969 15.2031C15.1157 15.2031 15.4214 15.0764 15.6469 14.851L19.6466 10.851C19.7585 10.7396 19.8473 10.6072 19.908 10.4613C19.9686 10.3155 19.9999 10.1591 20 10.0012C20.0001 9.84327 19.969 9.68687 19.9085 9.54097C19.8481 9.39507 19.7594 9.26255 19.6476 9.151Z"-->
            <!--                  fill="#0349A8"-->
            <!--                />-->
            <!--              </svg>-->
            <!--              {{ $t("LogOut") }}-->
            <!--            </button>-->
          </div>
        </div>
        <div class="col-lg-8">
          <div class="personal-info pt-sm-5">
            <Form
              @submit="updateProfile"
              :validation-schema="schema"
              class="row"
            >
              <h2 class="title">
                {{
                  this.$i18n.locale == "ar"
                    ? "المعلومات الشخصية"
                    : "Personal information"
                }}
              </h2>
              <div class="col-md-6 mb-3">
                <label for=""
                  >{{ $t("FullName") }}
                  <span class="text-danger">*</span></label
                >
                <Field
                  class="contact-field"
                  name="Name"
                  type="text"
                  :placeholder="$t('FullName')"
                  :value="UserData.name"
                />
                <ErrorMessage name="Name" class="error-message" />
              </div>
              <div class="col-md-6 mb-3">
                <label for=""
                  >{{ $t("Email") }} <span class="text-danger">*</span></label
                >
                <Field
                  class="contact-field"
                  name="Email"
                  type="email"
                  :placeholder="$t('email')"
                  :value="UserData.email"
                />
                <ErrorMessage name="Email" class="error-message" />
              </div>
              <div class="col-md-6 mb-3">
                <label for="">{{ $t("Select Gender") }}</label>
                <Field
                  class="contact-field"
                  as="select"
                  name="Gender"
                  v-model="genderSelected"
                >
                  <option value="" disabled hidden>
                    {{ $t("Select Gender") }}
                  </option>
                  <option value="male">
                    {{ $t("Male") }}
                  </option>
                  <option value="female">
                    {{ $t("Female") }}
                  </option>
                </Field>
                <ErrorMessage name="Gender" class="error-message" />
              </div>
              <div class="col-md-6 mb-3">
                <label for="">{{ $t("Profession") }}</label>
                <Field
                  class="contact-field"
                  name="Profession"
                  type="text"
                  :placeholder="$t('Profession')"
                  :value="UserData.profession"
                />
                <ErrorMessage name="Profession" class="error-message" />
              </div>
              <div class="col-md-6 mb-3">
                <label for=""
                  >{{ $t("IdNumber") }}
                  <span class="text-danger">*</span></label
                >
                <Field
                  class="contact-field id_number"
                  name="Id_number"
                  type="number"
                  :placeholder="$t('IdNumber')"
                  :value="
                    UserData.identity_card_number
                      ? Number(UserData.identity_card_number)
                      : ''
                  "
                />
                <ErrorMessage name="Id_number" class="error-message" />
              </div>
              <div class="col-md-6 mb-3">
                <label for=""
                  >{{ $t("PhoneNumber") }} <span class="text-danger">*</span>
                </label>
                <input id="phone" type="tel" name="phone" />
                <p id="output" class="error-message mt-1 mb-1"></p>
              </div>
              <div class="col-md-6 mb-3">
                <label for="">{{ $t("city") }}</label>
                <Field name="city" v-slot="{ field }">
                  <multiselect
                    v-model="value"
                    open-direction="bottom"
                    @select="PickId"
                    v-bind="field"
                    :options="cities"
                    :searchable="false"
                    :placeholder="
                      this.$i18n.locale == 'ar'
                        ? 'اختر المدينة'
                        : 'Choose the city'
                    "
                    label="name"
                    track-by="name"
                    selectLabel=""
                    :selectedLabel="
                      this.$i18n.locale == 'ar' ? 'المحدد' : 'Selected'
                    "
                    :deselectLabel="
                      this.$i18n.locale == 'ar'
                        ? 'اضغط للإزالة'
                        : 'Press enter to remove'
                    "
                  >
                  </multiselect>
                </Field>
                <ErrorMessage name="city" class="error-message" />
              </div>
              <div class="col-md-6"></div>
              <div class="col-md-6"></div>
              <div class="col-md-6" style="text-align: end">
                <button class="btn-main" type="submit">
                  {{ $t("Save_Edit") }}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookie from "cookie-universal";
const cookie = Cookie();
import axios from "axios";
import { notify } from "@kyvg/vue3-notification";
// import vee-validate
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { configure } from "vee-validate";
import intlTelInput from "../../intl-tel-input.js";
import Multiselect from "vue-multiselect";
global.jQuery = require("jquery");
var $ = global.jQuery;
window.$ = $;
const validName = (value) =>
  value && value.trim().length > 0 && !value.startsWith(" ");

export default {
  data() {
    return {
      cities: [],
      value: "",
      city_Id: "",
      AvatarChanged: false,
      lang: cookie.get("lang"),
      genderSelected: cookie.get("Userdata").gender,
      UserData: cookie.get("Userdata"),
      name: cookie.get("Userdata").name,
      email: cookie.get("Userdata").email,
      phone: cookie.get("Userdata").phone,
      image: cookie.get("Userdata").image,
      cityObj: cookie.get("Userdata").city,
      CompanyName: cookie.get("Companydata")
        ? cookie.get("Companydata").name
        : "",
      commercial_register: cookie.get("Companydata")
        ? cookie.get("Companydata").commercial_register
        : "",
    };
  },
  setup() {
    configure({
      validateOnInput: true,
    });
    const schema = yup.object({
      Name: yup
        .string()
        .required(
          cookie.get("lang") == "ar" ? "حقل الاسم  مطلوب" : "name required"
        )
        .min(
          4,
          cookie.get("lang") == "ar"
            ? "يجب أن يكون الاسم من 4 أحرف على الأقل"
            : "name must be at least 4 characters"
        )
        .test(
          "validName",
          cookie.get("lang") === "ar"
            ? "تنسيق الاسم غير صالح."
            : "Invalid name format.",
          validName
        ),

      Email: yup
        .string()
        .required(
          cookie.get("lang") == "ar"
            ? "حقل البريد الإلكتروني مطلوب"
            : "email is required"
        )
        .email(
          cookie.get("lang") == "ar"
            ? "يجب إدخال بريد إلكتروني صالح"
            : "email must be valid"
        ),
      Profession: yup
        .string()
        .required(
          cookie.get("lang") == "ar"
            ? "حقل المهنة  مطلوب"
            : "Profession is required"
        )
        .min(
          4,
          cookie.get("lang") == "ar"
            ? "يجب أن يكون الاسم من 4 أحرف على الأقل"
            : "name must be at least 4 characters"
        )
        .test(
          "validName",
          cookie.get("lang") === "ar"
            ? "تنسيق المهنة غير صالح."
            : "Invalid profession format.",
          validName
        ),
      Gender: yup
        .string()
        .required(
          cookie.get("lang") == "ar" ? "حقل الجنس  مطلوب" : "gender is required"
        ),
      city: yup
  .object()
  .typeError(cookie.get("lang") == "ar" ? "حقل المدينة مطلوب" : "City is required")
  .test(
    "isValidCity",
    cookie.get("lang") == "ar" ? "المدينة غير صالحة" : "Invalid city",
    (value) => value && typeof value === "object" && Object.keys(value).length > 0
  )
  .required(cookie.get("lang") == "ar" ? "حقل المدينة مطلوب" : "City is required"),

      Id_number: yup
        .mixed()
        .test(
          "number",
          (field) => {
            return cookie.get("lang") == "ar"
              ? "رقم الهوية مطلوب"
              : "id number is required";
          },
          (value) => typeof value === "number"
        )
        .test(
          "number",
          (field) => {
            return cookie.get("lang") == "ar"
              ? "رقم الهوية يجب أن يكون 10 أرقام"
              : "id Number must be exactly 5 numbers";
          },
          (value) => value.toString().length === 10
        )
        .required(cookie.get("lang") == "ar"),
    });
    return {
      schema,
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  methods: {
    // saveChanges() {
    //   localStorage.setItem(
    //     "userCity",
    //     JSON.stringify({ id: this.city_Id, name: this.value.name })
    //   );
    // },

    SetNewAvatart(e) {
      this.AvatarChanged = true;
      this.image = event.target.files[0];
      var reader = new FileReader();
      reader.onload = function (e) {
        $("#target-avatar").attr("src", e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    },
    // logOut() {
    //   cookie.remove("Token");
    //   cookie.remove("Userdata");
    //   cookie.remove("Companydata");
    //   cookie.remove("CompanyToken");
    //   this.$router.go();
    // },

    PickId() {
      this.city_Id = this.value.id;

      cookie.set("Userdata", {
        ...cookie.get("Userdata"),
        city: { id: this.city_Id, name: this.value.name },
      });
      this.cityObj = { id: this.city_Id, name: this.value.name };
      localStorage.setItem(
        "userCity",
        JSON.stringify({ id: this.city_Id, name: this.value.name })
      );
    },

    updateProfile(values) {
      const Fdata = new FormData();
      Fdata.append("name", values.Name);
      Fdata.append("email", values.Email);
      Fdata.append("phone", this.phone);
      Fdata.append("subscribed_latest_news", 1);
      Fdata.append("city_id", this.city_Id);
      Fdata.append("gender", values.Gender);
      Fdata.append("profession", values.Profession);
      Fdata.append("identity_card_number", values.Id_number);
      if (this.AvatarChanged) {
        Fdata.append("image", this.image);
      }
      axios
        .post(`/user/profile/update`, Fdata, {
          headers: { Authorization: `Bearer ${this.$store.state.Token}` },
        })
        .then((res) => {
          if (res.status == 200) {
            this.$store.state.serviceOrder.step2.requester_type = "user";
            cookie.set("Userdata", res.data.data.user);
            cookie.set("Userdata", {
              ...res.data.data.user,
              city_id: this.city_Id,
            });

            this.$store.state.Userdata = res.data.data.user;
            notify({
              type: "success",
              text:
                this.$i18n.locale == "ar"
                  ? "تمت الإضافة بنجاح"
                  : "Added successfully",
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        })
        .catch(function (error) {
          console.log("Error: ", error);
          notify({
            type: "warning",
            text: error.response.data.message,
          });
        });
    },
  },
  mounted() {
    this.$store.dispatch("getCities").then((r) => {
      this.cities = this.$store.state.cities;
      const savedCity = localStorage.getItem("userCity");
      if (savedCity) {
        this.cityObj = JSON.parse(savedCity);
        this.cities.forEach((element) => {
          if (element.id === this.cityObj.id) {
            this.value = element;
            this.city_Id = element.id;
          }
        });
      }
    });
    const output = document.querySelector("#output");
    const inputElement = document.querySelector("#phone");
    inputElement.value = this.phone;
    const iti = intlTelInput(inputElement, {
      i18n: {
        searchPlaceholder: cookie.get("lang") ? "بحث" : "Search",
      },
      initialCountry: "auto",
      nationalMode: true,
      // geoIpLookup: (callback) => {
      //   fetch("https://ipapi.co/json")
      //     .then((res) => res.json())
      //     .then((data) => callback(data.country_code))
      //     .catch(() => callback("us"));
      // },
      geoIpLookup: (callback) => {
        fetch("https://ipapi.co/country")
          .then((res) => {
            if (!res.ok) {
              throw new Error("Network response was not ok");
            }
            return res.text();
          })
          .then((data) => callback(data))
          .catch(() => callback("us"));
      },
      utilsScript:
        "https://cdn.jsdelivr.net/npm/intl-tel-input@23.7.3/build/js/utils.js",
    });

    const handleChange = () => {
      let text;
      if (cookie.get("lang") == "ar") {
        if (inputElement.value != "") {
          text = iti.isValidNumber()
            ? ""
            : "رقم غير صحيح - الرجاء إعادة المحاولة";
          if (iti.isValidNumber()) {
            this.phone = iti.getNumber();
          }
        } else {
          text = "الرجاء ادخال رقم صحيح";
        }
      } else {
        if (inputElement.value != "") {
          text = iti.isValidNumber() ? "" : "Invalid number - please try again";
          if (iti.isValidNumber()) {
            this.phone = iti.getNumber();
          } else {
            this.phone = false;
          }
        } else {
          text = "Please enter a valid number below";
        }
      }
      const textNode = document.createTextNode(text);
      output.innerHTML = "";
      output.appendChild(textNode);
    };

    // listen to "keyup", but also "change" to update when the user selects a country
    inputElement.addEventListener("change", handleChange);
    inputElement.addEventListener("keyup", handleChange);
  },
  computed: {
  cityName() {
    return Array.isArray(this.cityObj?.name) ? this.cityObj.name[0] : this.cityObj?.name || "";
  }
}
};


</script>
